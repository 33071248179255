body {
    font-family: Arial, sans-serif;
    background-color: #e9ecef;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    text-align: center;
  }
  
  img {
    margin-bottom: 20px;
    max-width: 100%;
    height: auto;
  }
  
  h2 {
    margin-bottom: 20px;
    font-size: 24px;
    color: #333;
  }
  
  form {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center the form elements */
  }
  
  input[type="text"],
  input[type="email"],
  input[type="password"],
  input[type="tel"] {
    width: 300px; /* Set a specific width */
    padding: 10px;
    margin-bottom: 20px; /* Space between input fields */
    border: 1px solid #ddd;
    border-radius: 5px;
    box-sizing: border-box;
    font-size: 16px;
    text-align: left; /* Align input text to the left */
  }
  
  input::placeholder {
    color: #aaa; /* Placeholder text color */
    text-align: left; /* Align the placeholder text to the left */
  }
  
  button {
    width: 200px; /* Set the same width as input fields */
    padding: 10px 15px;
    border: none;
    background-color: #007bff;
    color: white;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  button:hover {
    background-color: #0056b3;
  }
  
  p {
    font-size: 14px;
    color: #007bff;
    cursor: pointer;
    margin: 10px 0;
  }
  
  p:hover {
    text-decoration: underline; /* Underline on hover for "Forgot Password?" */
  }
  
  h3 {
    margin-top: 20px;
    font-size: 14px;
    color: #555;
  }
  
  h3 a {
    color: #007bff;
    text-decoration: none;
    font-weight: bold;
  }
  
  h3 a:hover {
    text-decoration: underline; /* Underline on hover for "Sign up here" */
  }
  