/* Center the container */
.reviews-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 16px;
  padding: 16px;
  padding-bottom: 15rem;
}

.review-card {
  background-color: #f9f9f9;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 16px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 250px;
  text-align: center;
}

.review-card:hover {
  background-color: #ffffff; /* Lighter color on hover */
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2); /* Stronger shadow on hover */
}
.review-name {
  font-weight: bold;
  margin-bottom: 4px;
  display: block;
}

.review-date {
  color: #757575;
  font-size: 0.9em;
  margin-bottom: 8px;
  display: block;
}

.review-rating {
  margin-bottom: 8px;
}

.star {
  font-size: 1.2em;
  margin-right: 2px;
}

.star.filled {
  color: #FFD700; /* Yellow color for filled stars */
}

.star.empty {
  color: #e0e0e0; /* Gray color for empty stars */
}

.review-text {
  font-size: 1em;
  color: #333;
  line-height: 1.4;
  display: block;
  text-decoration: none; /* Remove underline */

}
.review-text:hover {
  text-decoration: none; /* Prevent underline on hover */
}

.paragraph {
  font-size: 24px;
  font-weight: bold;
  font-style: italic;
  text-decoration: none; /* Remove underline */
  cursor: default; /* Prevent pointer change on hover */
}

.subparagraph {
  font-size: 15px;
  font-style: italic;
  text-decoration: none; /* Remove underline */
  cursor: default; /* Prevent pointer change on hover */
}

/* Ensure no hover effects */
.paragraph:hover,
.subparagraph:hover {
  text-decoration: none; /* Prevent underline on hover */
  color: inherit; /* Keep text color consistent */
}
