/* Sidebar Styling for Desktop */
.sidebar {
  width: 300px;
  background-color: #fff;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  padding: 20px;
  overflow-y: auto;
  z-index: 1000;
  transition: transform 0.3s ease; /* Add transition for smooth opening/closing */
  
}

.sidebar.open {
  transform: translateX(0); /* Sidebar visible */
  padding-top: 20px; /* Provide space between filters and the toggle button */
}

/* Ensure toggle button is hidden on desktop */
.toggleButton {
  display: none; /* Default state: hidden */
}


@media (max-width: 768px) {
  .sidebar {
    position: fixed;
    top: 0;
    left: 0; /* Align sidebar to the left edge of the page */
    width: 100%;
    height: 100vh;
    transform: translateX(-100%); /* Sidebar hidden off-screen */
    z-index: 1000;
  }

  .sidebar.open {
    transform: translateX(0); /* Slide in */
    padding-top: 10px; /* Ensure padding so filters don't clash with button */
  }
}

.closeButton:hover {
  color: #007bff; /* Highlight color on hover */
}

.closeButton {
  display: none; /* Hide by default */
}
@media (max-width: 768px) {
  .toggleButton {
    position: fixed; /* Keep the button fixed */
    top: calc(50px - 10px); /* Move it 10px upwards from its current position */
    right: calc(100% - 60px); /* Keep it near the right edge */
    z-index: 1100; /* Ensure it stays above other elements */
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 32px;
    font-weight: bold;
    background-color: #007bff; /* Optional: Button background */
    color: white; /* Optional: Text color */
    cursor: pointer;
  }

  .closeButton {
    display: flex; /* Show on mobile screens */
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 10px;
    right: 10px;
    width: 40px; /* Size of the circle */
    height: 40px;
    background-color: black; /* Black circle background */
    color: white; /* White "X" */
    border: none;
    border-radius: 50%; /* Makes it a circle */
    font-size: 18px; /* Size of the "X" */
    font-weight: bold; /* Makes the "X" bolder */
    cursor: pointer;
    z-index: 1200; /* Above sidebar content */
    transition: transform 0.3s ease, background-color 0.3s ease;
  }
  
  .closeButton:hover {
    color: #007bff; /* Highlight color on hover */
  }
}

.toggleButton:hover {
  background-color: #007bff; /* Darken on hover */
}

/* Input Field Styling */
.textField input {
  width: 100%;
  max-width: 250px; /* Set a reasonable max-width */
  padding: 10px;
  margin-top: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
}

/* Style for the Search Button */
.searchButton {
  width: 70%;
  background-color: #007bff; /* Main color for the button */
  color: white;
  border: none;
  padding: 12px 20px;
  font-size: 16px;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.searchButton:hover {
  background-color: #0056b3; /* Darken on hover */
  transform: translateY(-3px); /* Slight lift on hover */
}

.searchButton:active {
  transform: translateY(0px); /* Remove lift on click */
  background-color: #004080; /* Darker shade on click */
}

/* Style for the Reset Button */
.resetButton {
  width: 70%;
  background-color: #dc3545; /* Red for reset */
  color: white;
  border: none;
  padding: 12px 20px;
  font-size: 16px;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  margin-left: 10px; /* Add space between buttons */
}

.resetButton:hover {
  background-color: #c82333; /* Darker red on hover */
  transform: translateY(-3px); /* Slight lift on hover */
}

.resetButton:active {
  transform: translateY(0px); /* Remove lift on click */
  background-color: #a71d2a; /* Darker red on click */
}

/* Add responsive layout if needed */
.buttonsWrapper {
  display: flex;
  gap: 10px;
  margin-top: 20px;
  justify-content: flex-start; /* Align buttons to the left */
}

/* Remove the spinner arrows for number inputs */
.textField input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
  appearance: none; /* Default for Chrome, Safari, Edge */
}

.textField input[type="number"]::-webkit-outer-spin-button,
.textField input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none; /* Chrome, Safari, Edge */
  margin: 0;
}

/* Label Styling */
.textField label {
  font-size: 14px;
  font-weight: 600;
  color: #333; /* Dark grey for better contrast */
  display: block;
  margin-bottom: 5px; /* Space between label and input */
  text-align: left;
}

@media (max-width: 768px) {
  .textField label {
    font-size: 16px; /* Slightly larger font on mobile */
    color: #222; /* Slightly darker text for readability on smaller screens */
    text-align: left;
  }
}



@media (max-width: 768px) {

  .searchButton,
  .resetButton {
    margin-bottom: 15rem; /* Add padding under the buttons */
  }
}
