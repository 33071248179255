/* carDetailsPage.module.css */

/* Container for the carousel and vehicle info sections */
.container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap; /* Allow wrapping of content */
  margin: 0 auto;
  width: 100%;
  max-width: 1200px;
  padding-top: 2rem;
  position: relative;
  padding-bottom: 20rem;
}

/* Styles for the carousel section */
.carouselSection {
  width: 70%; /* Keep the same width */
  margin: 0 0 0 -3px; /* Adjust the left margin to move it slightly left */
  padding: 0; /* Ensure no extra padding interferes */
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Align content to the left */
}

/* Styles for the vehicle info section */
.vehicleInfoSection {
  width: 35%; /* Maintain the existing width */
  height: 100;
  margin-top: -20px; /* Move the section upwards */
  margin-left: -10px; /* Move the section slightly to the left */
  display: flex;
  flex-direction: column;
  align-self: flex-start; /* Keep it aligned to the top */
  position: relative; /* Ensure it remains in the flow of the container */
}
.carSpecsContainer {
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
  overflow: visible; /* Ensure content can expand naturally */
  display: block; /* Ensure it's not limited by flex or grid parents */
}


/* Car Specs list styling */
.carSpecsList {
  columns: 2;
  list-style-position: inside;
  column-gap: 40px;
}

.carSpecsList li {
  margin-bottom: 10px;
}

.carouselSection,
.vehicleInfoSection {
  flex-shrink: 0; /* Prevent shrinking of these sections */
}

.carTitle {
  font-size: 24px; /* Adjust font size for mobile */
  font-weight: bold; /* Make it stand out */
  text-align: left; /* Center-align text */
  color: white;
}

/* Web/Desktop Layout */
@media only screen and (min-width: 1024px) {
  .container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: middl; /* Center the image vertically */
  }

  .carouselSection {
    width: 60%; /* Image carousel takes up 60% */
  }

  .vehicleInfoSection {
    width: 35%; /* Vehicle info takes up 35% */
  }
}

/* Mobile Layout - Restored and Adjusted for Alignment */
/* Mobile Layout - Restored and Adjusted for Alignment and Spacing */
/* Mobile Layout - Adjusted for Car Specs Container */
@media only screen and (max-width: 768px) {
  .container {
    flex-direction: column; /* Stack content vertically */
  }

  .carouselSection {
    width: 90%; /* Relative width for responsiveness */
    max-width: 600px; /* Set a max width for the carousel */
    margin: 0 auto 2rem auto; /* Equal spacing beneath the carousel */
    padding: 0; /* Match the carousel padding */
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.25); /* Slightly stronger shadow for a clean pop */
    background-color: transparent; /* Transparent background */
    border-radius: 12px; /* Curved corners */
    overflow: hidden; /* Prevent content overflow */
  }

  .carouselSection img {
    width: 100%; /* Ensure the image scales correctly within its container */
    height: auto; /* Maintain the aspect ratio */
    object-fit: contain; /* Prevent the image from being cropped or stretched */
    display: block; /* Remove any inline spacing issues */
    margin: -100px 0 0 0; /* Move the image upwards with a negative top margin */
  }
  
  

  .vehicleInfoSection {
    width: 90%; /* Same width as the carousel */
    max-width: 600px; /* Match the carousel max-width */
    margin: -5rem auto 2rem auto; /* Move up slightly with negative margin */
    padding: 0; /* Remove extra padding */
  }

  .carSpecsContainer {
    width: 90%; /* Same width as the carousel */
    max-width: 600px; /* Match the carousel max-width */
    margin: 0 auto 2rem auto; /* Add margins to the left and right */
    padding: 20px; /* Add padding inside the container */
    background-color: #f9f9f9;
    border-radius: 8px;
    overflow: visible; /* Ensure content can expand naturally */
    display: block; /* Ensure it's not limited by flex or grid parents */
    min-width: 320px; /* Set a minimum width for better responsiveness */
  }

  .carSpecsContainer ul {
    padding: 10px; /* Add padding for readability */
  }

  .carTitle {
    font-size: 20px; /* Keep the existing font size */
    font-weight: bold; /* Keep the bold style */
    color: white; /* Keep the existing color */
    margin: 10px 0 0 43px; /* Push the title down (10px) and to the right (20px) */
    position: relative; /* Ensure positioning remains relative for adjustments */
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .carInfo {
    text-align: center; /* Center align the car info on mobile */
    margin-bottom: 20px;
    padding: 10px 0;
    border-bottom: 2px solid #ddd; /* Add a subtle divider */
    width: 100%; /* Ensure full width */
    box-sizing: border-box; /* Ensure proper padding alignment */
  }

  .carInfo h2 {
    font-size: 22px; /* Adjust for mobile screens */
  }

  .carInfo p {
    font-size: 16px; /* Subtle font size for supporting text */
    margin-top: 5px; /* Small spacing for better readability */
  }
}
