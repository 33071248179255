/* Center the entire forgot password section */
.forgotPasswordContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    padding: 20px;
    background-color: #f8f9fa;
  }
  
  /* Heading Styling */
  h1 {
    font-size: 24px;
    font-weight: bold;
    color: #333;
    margin-bottom: 20px;
    text-align: center;
  }
  
  /* Form Styling */
  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 400px;
  }
  
  /* Input Field Styling */
  input[type="email"] {
    width: 100%;
    max-width: 300px;
    padding: 12px;
    margin-bottom: 20px;
    border: 1px solid #ced4da;
    border-radius: 5px;
    box-sizing: border-box;
    font-size: 16px;
    background-color: #ffffff;
  }
  
  /* Button Styling */
  button {
    width: 100%;
    max-width: 300px;
    padding: 12px;
    margin: 10px 0;
    border: none;
    background-color: #007bff;
    color: white;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
  }
  
  button:hover {
    background-color: #0056b3;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  }
  
  /* Media Query for Mobile */
  @media (max-width: 600px) {
    h1 {
      font-size: 20px;
    }
  
    input[type="email"],
    button {
      max-width: 100%;
      width: 100%;
    }
  }
  