.App {
  text-align: center;
  background: linear-gradient(to bottom right, #005aa7, #fffde4);
  min-height: 100vh; /* full screen height */
}


.App-header {
  /*background-color: #282c34;*/
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

label {
  margin: 10px 0;
}

input {
  margin: 5px 0;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

button {
  margin-top: 20px;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: #61dafb;
  color: white;
  font-size: 16px;
  cursor: pointer;
}
