/* Container for centering logo and form */
.loginContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 20px;
}

/* Logo Styling */
.logo {
  margin-bottom: 20px;
  width: 300px;
  height: auto;
}

/* Wrapper for input fields */
.inputWrapper {
  width: 100%;
  margin-bottom: 20px;
}

/* Input field styling */
.inputField {
  width: 100%;
  max-width: 100%;
  padding: 12px;
  border: 1px solid #ced4da;
  border-radius: 5px;
  font-size: 16px;
  background-color: #f8f9fa;
}

/* Error message styling */
.errorMessage {
  color: red;
  font-size: 14px;
  margin-top: 10px;
}

/* Submit button styling */
.submitButton {
  width: 100%;
  padding: 12px;
  border: none;
  background-color: #007bff;
  color: white;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.submitButton:hover {
  background-color: #0056b3;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
}

/* Forgot Password link styling */
.forgotPassword {
  font-size: 18px;
  color: #007bff;
  cursor: pointer;
  margin-top: 10px;
}

.forgotPassword:hover {
  text-decoration: underline;
}


/* Media Query for Mobile Devices */
@media (max-width: 600px) {
  .loginContainer {
    padding: 10px; /* Reduce padding on mobile */
  }

  .logo {
    width: 350px; /* Scale down logo */
    margin-bottom: 15px;
  }

  .inputField,
  .submitButton {
    width: 50%; 
  }

  .inputField {
    width: 90%; /* Slightly reduce width on small screens */
    font-size: 14px; /* Adjust font size for input fields */
  }

  .submitButton {
    width: 70%; /* Full-width button on small screens */
    padding: 10px;
    font-size: 15px;
  }

  .forgotPassword {
    font-size: 18px; /* Adjust font size for "Forgot Password?" text */
  }

  .inputField {
    width: 100% !important; /* Force width on mobile */
    font-size: 14px;
  }

}