/* Set uniform background color for the entire page */
body {
  background-color: #f9f9f9; /* Adjust this color to your desired background */
  margin: 0;
  padding: 0;
}

/* Header Styles */
header {
  margin: 0;
  padding: 0;
  width: 100%;
}

/* Container for the contact page */
.wrapper {
  max-width: 1536px;
  margin: 0 auto;
  padding: 0 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 15rem;
}

/* Individual Section Containers */
.contact-section,
.faq-section {
  background-color: white;
  width: 100%;
  max-width: 800px;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  margin: 0 auto; /* Center the section horizontally */
  display: flex;
  flex-direction: column;
  align-items: center; /* Center items horizontally */
}

/* Add margin above the FAQ section */
.faq-section {
  margin-top: 20px; /* Adds space under the header */
  border-top: 1px solid #ddd;
  margin-bottom: 20px;
}

/* Contact Section */
.contact-details {
  margin-bottom: 20px;
  padding: 15px;
  background-color: #f9f9f9;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contact-details p {
  margin: 5px 0;
}

.contact-details p strong {
  margin-right: 5px; /* Adds space between "Phone number:" and "Email:" */
}

.contact-details a {
  color: #007bff;
  text-decoration: none;
}

.contact-details a:hover {
  text-decoration: underline;
}

/* Heading Spacing */
h1,
h2 {
  text-align: center;
  margin-bottom: 20px;
  color: #333;
  font-family: "Arial", sans-serif;
}

/* Form Styling */
.contact-form {
  width: 100%;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  align-items: center; /* Centers all form elements */
}

.form-row {
  width: 100%;
  max-width: 400px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center; /* Center each form row */
}

.form-row label {
  align-self: flex-start; /* Align labels to the left of the input field */
}

.form-row input,
.form-row textarea {
  width: 100%;
  min-width: 400px;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 6px;
  box-sizing: border-box;
  transition: border-color 0.3s ease;
}

.form-row input:focus,
.form-row textarea:focus {
  border-color: #007bff;
  outline: none;
}

button {
  display: block;
  width: 100%;
  max-width: 200px;
  background-color: #007bff;
  color: white;
  padding: 12px;
  border: none;
  border-radius: 6px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  margin-top: 20px; /* Add some space above the button */
}

button:hover {
  background-color: #193df1;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
}

/* Error and Success Messages */
.form-error,
.form-success {
  text-align: center;
  padding: 10px;
  border-radius: 4px;
}

.form-error {
  color: #d9534f;
  background-color: #f2dede;
}

.form-success {
  color: #007bff;
  background-color: #f2dede;
}

/* FAQ Section */
.faq-question {
  position: relative;
  padding: 20px 0;
  width: 100%;
  border-bottom: 1px solid #ddd;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.faq-question h3 {
  font-size: 18px;
  color: #333;
  margin: 0;
  display: inline-block;
}

.arrow {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 18px;
  color: #007bff;
  transition: transform 0.3s ease;
}

.faq-answer {
  padding: 0;
  max-height: 0;
  overflow: hidden;
  font-size: 16px;
  color: #666;
  line-height: 1.6;
  transition: max-height 0.3s ease, padding 0.3s ease;
}

.faq-question.active .faq-answer {
  padding: 15px;
  max-height: 200px;
  background-color: #f7f7f7;
  border-radius: 5px;
  margin-top: 10px;
}

.faq-question.active .arrow {
  transform: translateY(-50%) rotate(180deg);
}

.faq-question:hover {
  background-color: #f9f9f9;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .contact-container {
    margin: 0;
    position: relative;
  }

  .form-row {
    width: 90%; /* Adjust width to ensure it fits smaller screens */
  }

  .form-row input,
  .form-row textarea {
    width: 100%; /* Ensure input and textarea fields do not exceed form width */
    min-width: unset; /* Remove any fixed minimum width that could cause overflow */
    box-sizing: border-box; /* Maintain padding and borders within width */
  }

  button {
    width: 50%; /* Adjust button width for smaller screens */
    font-size: 14px;
    padding: 10px;
  }

  .contact-section,
  .faq-section {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  /* Fix the blue triangles (.arrow) */
  .arrow {
    font-size: 14px; /* Reduce size for smaller screens */
    right: 10px; /* Adjust distance from the right edge */
    transform: translateY(-50%); /* Keep it vertically centered */
  }

  .faq-question {
    padding-right: 30px; /* Add space to prevent overlap with text */
  }
}


/* Styling for the Call Now button in Contact form */
.call-button {
  display: inline-block;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  background-color: #007bff; /* Matches the CarComp button color */
  border: none;
  border-radius: 8px;
  text-align: center;
  text-decoration: none;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  margin: 20px auto;
  cursor: pointer;
}

.call-button:hover {
  background-color: #0056b3; /* Slightly darker shade on hover */
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2); /* Add shadow on hover */
}

.call-instruction {
  text-align: center;
  font-size: 16px;
  margin-bottom: 15px;
  color: #333;
  text-decoration: none; /* Remove underline */
}

.call-instruction:hover {
  text-decoration: none; /* Remove underline */
}
