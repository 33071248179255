/* General styles */
h1 {
  margin: 0;
}

p {
  all: unset;
  padding: 0;
  margin: 0;
}

/* Remove underline for all text elements in the car card */
.cardDetail h2,
.cardDetail p,
.cardDetail span {
  text-decoration: none; /* Ensure no underline for these elements */
}

.cardDetail:hover h2,
.cardDetail:hover p,
.cardDetail:hover span {
  text-decoration: none; /* Prevent underline on hover */
}

/* Car Card Wrapper */
.carCardWrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 3 cards per row */
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  padding: 0 10px;
  justify-content: center;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}

@media (max-width: 770px) {
  .carCardWrapper {
    grid-template-columns: repeat(2, 1fr); /* 2 cards per row on medium screens */
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (max-width: 576px) {
  .carCardWrapper {
    grid-template-columns: 1fr; /* 1 card per row on small screens */
    justify-content: center;
    align-items: center;
    padding: 0; /* Remove extra padding on mobile */
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 200px;
    width: 100%; /* Ensure full width */
    max-width: 400px; /* Limit width for better centering */
  }
}

.cardDetail {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.15);
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 6px;
  cursor: pointer;
  transition: transform 0.3s ease;
  position: relative; /* Required for absolute positioning of the price */
}

.cardContent {
  flex-grow: 1; /* Allow content to grow and take available space */
  display: flex;
  flex-direction: column;
}

.cardHeader {
  text-align: center;
  color: black;
  font-size: 26px;
  font-weight: 600;
  margin: 0; /* Remove margin to prevent gaps */
  flex: 1; /* Allow the title to take full height */
}

.cardCarousel {
  width: 100%;
  height: 250px; /* Adjust height as needed */
  margin-bottom: 10px;
}

.carouselImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
}

.carCardWrapper.filtered .cardDetail {
  margin-top: 100rem !important; /* Reduce or reset the margin on filtered listings */
}

.cardDetail img {
  width: 100%;
  height: 250px;
  object-fit: cover;
}
.stock {
  color: black;
  font-size: 16px;
}

.autoSales {
  color: black;
  font-size: 18px;
  font-weight: 300;
}

.carSpecsWrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  padding-top: 8px;
}

.carSpecsTextWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 6px;
  border-radius: 6px;
  padding: 8px 0;
  border: 1px solid #007bff;
}

.infoWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: auto;
}

.milesWrapper {
  font-size: 24px;
  font-weight: 600;
  font-style: italic;
  color: black;
  margin-top: 0;
}

.priceWrapper {
  font-size: 30px;
  font-weight: 700;
  color: rgb(77, 107, 227);
}

/* Wrapper for the call button */
.callButtonWrapper {
  margin-top: 10px;
  text-align: center;
}

/* Call Button Styles */
.callButton {
  display: inline-block;
  padding: 10px 20px;
  background-color: #28a745; /* Green background */
  color: white;
  font-size: 16px;
  font-weight: bold;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.callButton:hover {
  background-color: #218838; /* Darker green on hover */
  transform: scale(1.05); /* Slightly enlarge on hover */
}

.callButton:active {
  background-color: #1e7e34; /* Even darker green when pressed */
  transform: scale(0.95); /* Slightly shrink when clicked */
}
