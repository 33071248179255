/* Container */
.container {
  padding: 20px;
}

/* Header styling */
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.profileIcon {
  position: absolute;
  top: 20px;
  right: 20px;
  color: #289ed8;
  font-size: 24px;
  cursor: pointer;
}

/* Table styling */
.tableWrapper {
  overflow-y: auto;
  max-height: 80vh;
  padding: 20px;
  margin-top: 20px;
  border: 1px solid #ccc;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

thead {
  background-color: #f8f9fa;
}

th, td {
  border: 1px solid #ddd;
  padding: 15px; /* Increased padding for better spacing */
  text-align: center;
}

th {
  background-color: #e9ecef;
  font-weight: bold;
}

/* Zebra striping for rows */
tbody tr:nth-child(odd) {
  background-color: #f9f9f9;
}

/* Images in table */
td img {
  width: 100%;
  height: auto;
  object-fit: cover;
  max-height: 180px; /* Ensure the images are consistent in size */
}

/* Carousel styles */
.carousel {
  position: relative;
  width: 100%;
  height: 200px;
  overflow: hidden;
}

.carousel img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.arrowLeft, .arrowRight {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(255, 255, 255, 0.8);
  color: #333;
  border: none;
  cursor: pointer;
  padding: 10px;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease;
}

.arrowLeft {
  left: 10px;
}

.arrowRight {
  right: 10px;
}

.arrowLeft:hover, .arrowRight:hover {
  background-color: rgba(255, 255, 255, 1);
}

/* Action buttons */
.deleteButton, .editButton {
  background-color: #e74c3c;
  color: white;
  border: none;
  cursor: pointer;
  padding: 10px;
  border-radius: 50%;
  font-size: 18px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease;
}


/* Add margin to create space between buttons */
.deleteButton {
  background-color: #e74c3c;
}

.editButton {
  background-color: #3498db;
}

.deleteButton:hover {
  background-color: #c0392b;
}

.editButton:hover {
  background-color: #2980b9;
}

/* Modal Form */
.modalForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%; /* Make it full-width */
  gap: 15px; /* Space between inputs */
  padding: 20px; /* Padding within the modal */
  max-width: 600px; /* Limit width on larger screens */
  margin: 0 auto; /* Center the form in the modal */
}

.modalForm input {
  width: 100%;
  padding: 12px;
  font-size: 16px;
  border: 1px solid #ced4da;
  border-radius: 5px;
}


/* Modal Buttons */
.modalForm button {
  width: 100%; /* Full-width buttons */
  padding: 12px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.modalForm button:hover {
  background-color: #0056b3;
}

.submitButton {
  background-color: #28a745; /* Submit button with green */
}

.cancelButton {
  background-color: #dc3545; /* Cancel button with red */
}

.submitButton:hover {
  background-color: #218838;
}

.cancelButton:hover {
  background-color: #c82333;
}

/* Main container for specifications */
.specificationSection {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Container specifically for the input and button alignment */
.specificationInput {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  margin-top: 10px;
}

/* Styling for the input field */
.specificationInput input {
  width: 200px; /* Fixed width for consistency */
  height: 40px;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
  font-size: 16px;
  box-sizing: border-box;
}

/* Styling for the button */
.specificationInput button {
  width: 50px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
  font-size: 16px;
  margin-top: -10px; /* Adjust this value to move the button upwards */
}

.specificationInput button:hover {
  background-color: #0056b3;
}



ul {
  padding-left: 20px;
  margin-top: 10px;
}

ul li {
  margin-bottom: 5px;
  display: flex;
  align-items: center;
}

ul li button {
  margin-left: 10px;
  background: none;
  border: none;
  color: red;
  cursor: pointer;
  font-size: 16px;
}

/* Add New Listing Button */
.addListingButton {
  background-color: #007bff; /* Blue background */
  color: white;
  font-size: 16px;
  padding: 12px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  width: 20%; /* Make button full-width */
  margin-bottom: 20px;
}

.addListingButton:hover {
  background-color: #0056b3; /* Darker blue on hover */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Add shadow on hover */
}

.imagePreview {
  display: flex;
  gap: 10px;
  overflow-x: auto;
  padding: 10px;
  border: 1px solid #ccc;
  overflow-y:scroll;
}

.imageItem {
  width: 100px;
  height: 100px;
  border: 1px solid #ddd;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.imageItem {
  position: relative; /* Ensures the delete button positions relative to this container */
  width: 100px;
  height: 100px;
  border: 1px solid #ddd;
  border-radius: 5px;
  overflow: hidden;
}

.deleteImageButton {
  position: absolute;
  top: -6px; /* Position near the top */
  right: 1px; /* Position near the right */
  background-color: red !important;
  color: white;
  border: none;
  border-radius: 20% !important; /* Makes it circular */
  width: 5px !important; /* Adjust size as needed */
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 16px;
  line-height: 1; /* Ensures the "X" is centered */
  font-weight: bold;
  z-index: 10;
  transition: transform 0.2s ease, background-color 0.3s ease;
}

.deleteImageButton:hover {
  background-color: darkred; /* Darker red on hover */
  transform: scale(1.1); /* Slightly larger on hover */
}
