/*inventoryModule.css*/
/* Header Styling */
.headerWrapper {
  position: sticky;
  top: 0;
  background-color: #005aa7;
  padding:  0;
  z-index: 1000;
}


/* Fixed Header */
.header {
  background-color: white;
  padding: 10px 20px; /* Add padding for consistent spacing */
  border-bottom: none;
  display: flex;
  flex-direction: row; /* Align items in a row */
  align-items: center;
  justify-content: center; /* Center content within the header */
  width: 100%;
  transition: top 0.3s;
  color: #005aa7;
  box-sizing: border-box; /* Ensure padding is included in width calculation */
  position: relative; /* Allow absolute positioning inside */
}

/* Header Content */
.headerContent {
  display: flex;
  justify-content: flex-start; /* Align items to the left */
  align-items: center;
  width: 100%;
  gap: 1rem;

  @media (max-width: 756px) {
    flex-direction: column; 
    gap: 10px;
  }
}

.logo {
  font-size: 1.5rem;
  font-weight: bold;
  color: #005aa7;
  padding-left: 1rem;

  @media (max-width: 756px) {
    padding-left: 0;
  }
 
}


.iconWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #007bff;
  width: 40px;
  height: 40px;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  cursor: pointer;

  img {
    margin: 0;
  }
}

/* Navigation Styling */

.nav ul {
  list-style: none;
  display: flex;
  gap: 20px;
  margin: 0 !important;
  padding-left: 0px !important;

  @media (max-width: 756px) {
    gap: 10px;
  }
}

/* Header Styling  end*/

/* inventoryWrapper styles start */

h1 {
  margin: 0 !important;
}

p {
  all: unset;
  padding: 0;
  margin: 0;
}

.inventoryWrapper {
  max-width: 1536px;
  margin: 0 auto;
  text-align: start !important;
  padding: 0 16px;
  display: flex;
  gap: 1.5rem;
  margin-top: 2rem;
  margin-bottom: 2rem;

  @media (max-width: 1300px) {
    flex-direction: column;
  }
}
/* inventoryWrapper styles end */

/* sidebar styles  start*/
.sidebar {
  position: sticky;
  top: 8px;
  height: fit-content;
  width: 300px;
  background: #d51616;
  box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.15);
  border-radius: 12px;
  padding: 16px 14px;

  @media (max-width: 1536px) {
    width: 240px;
  }

  @media (max-width: 1300px) {
    position: static;
    width: 100%;
    height: fit-content;
  }

  p {
    all: unset;
    padding: 0;
    margin: 0;
  }
}

/* sidebar styles end */

/* card wrapper styles start  */

.cardWrapper {
  flex: 1;
  margin-top: 200rem;
}
/* card wrapper styles end  */

.filterWrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 10rem;

  @media (max-width: 1300px) {
    display: grid !important;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 12px;
    grid-row-gap: 12px;
  }

  @media (max-width: 576px) {
    grid-template-columns: repeat(2, 1fr);
  }
}

.carHeaderWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 991px) {
    flex-direction: column;
    align-items: start;
    gap: 10px;
  }

  h1 {
    font-size: 20px;
    font-weight: 600;
    text-align: start;
  }
}

.cardIcons {
  cursor: pointer;
}

.cardsHeaderDiv {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;

  @media (max-width: 565px) {
    flex-direction: column;
    width: 100%;
  }
}

.cardsSwitchWrapper {
  border: 1px solid #ddd;
  border-radius: 6px;
  padding: 10px 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;

  @media (max-width: 565px) {
    width: 100%;
    gap: 4rem;
  }

  img {
    margin: 0;
    @media (max-width: 565px) {
      width: 26px;
    }
  }
}

/* Body Styling */
body {
  font-family: Arial, sans-serif;
  background-color: #f8f9fa;
  color: #333;
  line-height: 1.6;
  margin: 0;
  padding: 0;
}

/* Main Content Section */
main {
  padding: 20px;
}

/* Main Container for Video and Content */
.mainContainer {
  flex: 1;
  padding-left: 20px;
}

/* Arrows for Image Navigation */
.arrowLeft,
.arrowRight {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  cursor: pointer;
  padding: 5px;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease;
}

.arrowLeft {
  left: 50px;
  color: green;
  background-color: white;
}

.arrowRight {
  right: 5px;
  color: green;
  background-color: white;
}

.arrowLeft:hover,
.arrowRight:hover {
  background-color: rgba(0, 0, 0, 0.7);
}

/* Car Details */
.carDetails {
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 10px;
}

.carDetails h3 {
  font-size: 1.1rem;
  margin: 0;
  color: #007bff;
}

.carDetails p {
  margin: 0;
  font-size: 0.9rem;
  color: #333;
}

.contactInfo {
  text-align: right;
}

/* Navigation Styling */
.nav {
  margin-top: 0;
  background-color: white;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  padding: 1rem 0;
  height: 20px;
  transform: translateX(-20%);
}

/* Responsive Design for Small Screens */
@media (max-width: 768px) {
  .header {
    display: flex;
    flex-direction: column; /* Stack elements vertically */
    align-items: flex-start; /* Align items to the left */
  }

  .nav {
    transform: translateX(0); /* Reset the transform for proper alignment */
    justify-content: center; /* Align navigation items to the left */
    width: 100%; /* Ensure the nav takes up full width */
    margin-top: 0.5rem; /* Add spacing under the logo */
    order: 1; /* Ensure the nav comes after the logo */
  }
}

.nav a {
  color: #005aa7;
  text-decoration: none;
  padding: 10px 15px;
  font-weight: bold;
}

.nav a:hover {
  background-color: #005aa7;
  border-radius: 4px;
  color: white;
  font-weight: bold;
}


/* Video Section */
.videoSection {
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  z-index: 1;
}

/* Video Element */
.backgroundVideo {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  transform: translate(-50%, -50%);
}

/* Content Section */
.contentSection {
  position: relative;
  z-index: 2;
  padding: 20px;
  background-color: rgba(255, 255, 255, 0.9);
  border: 1px solid #ddd;
  border-radius: 5px;
  padding-top: 80px;
}


.carCard:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2);
}

/* Image Container */
.imageContainer {
  width: 100%;
  height: 200px; /* Fixed height for images */
  overflow: hidden;
}

.carImage {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensure the image fits nicely */
}

/* Reset Button Styling */
.resetButton {
  padding: 8px 16px;
  background-color: #dc3545;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.resetButton:hover {
  background-color: #c82333;
}

/* Smooth Scrolling */
html {
  scroll-behavior: smooth;
}

/* Toggle Button */
.toggleButton {
  margin: 20px auto;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.toggleButton:hover {
  background-color: #0056b3;
}

/* Media Query for Mobile Devices */
@media (max-width: 768px) {
  .carListingsContainer {
    grid-template-columns: 1fr; /* Stacks items vertically */
    padding: 10px;
    gap: 15px;
  }

  .carCard {
    width: 100%;
    max-width: none; /* Remove max-width constraint */
    height: auto; /* Allow the height to adjust based on content */
  }

  .imageContainer {
    height: 250px; /* Increase height for better image visibility */
  }

  .carDetails {
    padding: 10px 5px; /* Adjust padding for better use of space on mobile */
  }
  .videoSection {
    position: relative;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    z-index: 1;
  }

  /* Video Element */
  .backgroundVideo {
    position: relative;
    top: 0; /* Adjust to make sure video starts at the top */
    left: 50%;
    width: 100vw;
    height: 100vh;
    object-fit: cover;
    transform: translateX(-50%);
  }
}

.inventoryPage {
  display: flex;
  max-width: 1536px;
  margin: 0 auto; /* Center the entire layout */
  padding: 0 16px;
  padding-top: 2rem; /* Adds space below the header */
  justify-content: space-between; /* Align content inside properly */
}

.mainContent {
  flex: 1; /* Take up remaining space */
  padding-left: 0; /* Reset unnecessary padding */
  display: flex; /* Flexbox for better alignment */
  justify-content: center; /* Center align the car listings */
}

.carListingsContainer {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); /* Flexible card width */
  gap: 20px; /* Space between cards */
  padding: 20px;
  width: 100%;
  max-width: 1200px; /* Prevent the grid from exceeding this width */
  margin: 0 auto; /* Center the grid container */
  justify-content: center; /* Center-align the cards */
}

@media (max-width: 768px) {
  .inventoryPage {
    padding: 0; /* Remove excessive padding for mobile */
    margin: 0 10px; /* Add small margins on mobile */
    justify-content: start; /* Align to the left on mobile */
  }

  .mainContent {
    padding-left: 0; /* No padding for mobile */
    justify-content: start; /* Align to the left */
  }

  .carListingsContainer {
    grid-template-columns: 1fr; /* Stack cards vertically on mobile */
    gap: 15px; /* Adjust gap for mobile */
    padding: 10px;
    margin: 0 5px; /* Align slightly left and avoid touching edges */
  }

  .carCard {
    margin: 0 auto; /* Center the cards */
    width: 95%; /* Slightly smaller cards for better spacing */
  }
}
